import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import './header.css';
import { useEffect, useState } from 'react';

const WebHeader = (props: { title?: string }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [source, setSource] = useState<any>('');
  const { title } = props;
  useEffect(() => {
    if (localStorage.getItem('source')) {
      const source = localStorage.getItem('source');
      setSource(source);
    }

    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const handleTabletChange = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => setIsMobile(e.matches);

    mediaQuery.addListener(handleTabletChange);
    console.log(888, isMobile);
    return () => mediaQuery.removeListener(handleTabletChange);
  }, []);
  return (
    <>
      {source == 'miniApps' ? null : (
        <div>
          {isMobile ? (
            <div className='headerWrapper'>
              <span
                className='returnIcon'
                onClick={() => {
                  window.history.go(-1);
                }}
              >
                <LeftOutlined style={{ fontSize: 18 }} />
              </span>
              <span className='headerTitle'>{title}</span>
            </div>
          ) : (
            <div className='outHeader'>
              <div className='headerWrapperPc'>
                <span
                  className='returnIconP'
                  onClick={() => {
                    window.history.go(-1);
                  }}
                >
                  <LeftOutlined style={{ fontSize: 18 }} />
                </span>
                <span className='headerTitle'>{title}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default WebHeader;
