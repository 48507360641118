import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Card } from 'antd';
import Frame from '../../utils/frame';
import Block from '../../utils/block';
import './index.css';
import accountManager from '../account/accountManager';
import { getBase } from '../../apis/account';

// 办事网点页面
export default () => {
  const [isMobile, setIsMobile] = useState(false);
  let account = accountManager.getAccount();
  const ling = useRef<any>();

  const spots = [
    {
      name: '黄石市不动产登记中心',
      address: '开发区·铁山区园博大道289号市民之家一楼',
      contact: '0714-6225294',
      workTime:
        '除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理',
    },
    {
      name: '黄石港区政务服务中心',
      address: '黄石港区华新路16号',
      contact: '0714-3260609',
      workTime:
        '除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理',
    },
    {
      name: '西塞山区政务服务中心',
      address: '西塞山区飞云街8号',
      contact: '0714-6488678',
      workTime:
        '除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理',
    },
    {
      name: '西塞山区政务服务小屋',
      address: '西塞山区大智路17-副8号（市中心大华巷武商旁）',
      contact: '0714-6489915',
      workTime:
        '除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理',
    },
    {
      name: '下陆区政务服务中心',
      address: '下陆区杭州西路182号政务服务中心一楼',
      contact: '0714-6577500',
      workTime:
        '除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理',
    },
    {
      name: '黄石经济技术开发区·铁山区政务服务中心',
      address: '黄石市经济技术开发区189号磁湖高新大楼A栋',
      contact: '0714-6390081',
      workTime:
        '除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理',
    },
  ];

  //   黄石市不动产登记中心	开发区·铁山区园博大道289号市民之家一楼	0714-6225294	除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理
  // 黄石港区政务服务中心	黄石港区华新路16号	0714-3260609	除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理
  // 西塞山区政务服务中心	西塞山区飞云街8号	0714-6488678	除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理
  // 西塞山区政务服务小屋	西塞山区大智路17-副8号（市中心大华巷武商旁）	0714-6489915	除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理
  // 下陆区政务服务中心	下陆区杭州西路182号政务服务中心一楼	0714-6577500	除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理
  // 黄石经济技术开发区·铁山区政务服务中心	黄石市经济技术开发区189号磁湖高新大楼A栋	0714-6390081	除双休日及法定节假日外，8:30—12:00，14:30—17:30	已取得《不动产权证书》的业务可就近在2-6服务点办理

  useEffect(() => {
    (async () => {})();
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const handleTabletChange = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => setIsMobile(e.matches);

    mediaQuery.addListener(handleTabletChange);
    return () => mediaQuery.removeListener(handleTabletChange);
  }, []);

  return (
    <>
      {isMobile ? (
        <Frame
          grayBg
          style={{
            overflow: 'hidden',
            minHeight: '100vh',
            paddingBottom: '100px',
            // maxWidth: '75rem',
          }}
        >
          {/* <Ling ref={ling} /> */}

          <div
            style={{
              height: '100vh',
              background: 'url(https://cdn.coffeebeats.cn/beijing.png)',
              backgroundSize: '100% 100%',
              position: 'fixed',
              left: 0,
              right: 0,
              // maxWidth: '75rem',
            }}
          ></div>

          <div
            style={{
              position: 'relative',
              zIndex: 0,
              overflow: 'hidden',
              width: '100%',
              height: '175px',
            }}
          >
            <img
              className='chuchuIcon'
              src='https://cdn.coffeebeats.cn/%E5%8D%A1%E9%80%9A%E5%BD%A2%E8%B1%A1_%E6%A5%9A%E6%A5%9A%E6%83%A0%E6%83%A0.png'
            />
            {/*{title && <span style={{ position: 'absolute', top: '30px', left: 0, width: '750px', textAlign: 'center', color: 'white', fontSize: '70px', zIndex: 1 }}>{title}</span>}*/}
            {/* {title && <div>
          <span style={{ position: 'absolute', top: '18px', left: '254px', width: '600px', textAlign: 'center', color: '#353535', fontSize: '33px', zIndex: 1, textShadow: '5px 5px 5prx #ddd' }}>{title[0]}</span>
          <span style={{ position: 'absolute', top: '66px', left: '254px', width: '600px', textAlign: 'center', color: '#353535', fontSize: '28px', zIndex: 1, textShadow: '5px 5px 5prx #ddd' }}>{title[1]}</span>
          <span style={{ position: 'absolute', top: '111px', left: '254px', width: '600px', textAlign: 'center', color: '#353535', fontSize: '30px', zIndex: 1, textShadow: '5px 5px 5prx #ddd' }}>{title[2]}</span>
        </div>} */}
            <img
              src='https://cdn.coffeebeats.cn/%E5%9B%BE%E7%89%871.png'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '175px',
              }}
            />
          </div>

          <div
            className='indexButton'
            style={{ position: 'relative', zIndex: 1, background: 'unset' }}
          >
            <img
              className='buttonImage'
              src='https://cdn.coffeebeats.cn/ll.png'
            />
            <span style={{ color: '#333' }}>办事网点</span>
          </div>

          <div className='indexBottombuttonsWrapper'>
            {/* <Image className="imgScene" mode="widthFill" src="http://oss-open.aichan.info/imgs/hhhhhhh.png"/> */}
            <Card>
              {spots.slice(0, 6).map((q, i) => (
                <div
                  key={i}
                  className='contact-info'
                  style={{
                    display: 'flex',
                    padding: '24px 12px',
                    borderBottom: 'solid thin rgba(221,221,221,0.5)',
                  }}
                >
                  {/* <span style={{ color: i <= 2 ? 'red' : (i <= 3 ? 'orange' : 'gray') }}>{i+1}</span>.  */}
                  <div style={{ flex: 2 }}>
                    <div style={{ fontSize: '14px', marginBottom: '6px' }}>
                      {q.name}
                    </div>
                    <div style={{ fontSize: '11px', color: '#888' }}>
                      {q.address}
                    </div>
                  </div>
                  <div style={{ flex: 3 }}>
                    <div
                      style={{ fontSize: '14px', marginBottom: '6px' }}
                      onClick={() => {}}
                    >
                      {q.contact}
                    </div>
                    <div style={{ fontSize: '14px', color: '#888' }}>
                      {q.workTime}
                    </div>
                  </div>
                </div>
              ))}
            </Card>
          </div>
        </Frame>
      ) : (
        <Frame
          grayBg
          style={{
            overflow: 'hidden',
            minHeight: '100vh',
            paddingBottom: '100px',
            // maxWidth: '75rem',
          }}
        >
          {/* <Ling ref={ling} /> */}

          <div
            style={{
              height: '100vh',
              background: 'url(https://cdn.coffeebeats.cn/beijing.png)',
              backgroundSize: '100% 100%',
              position: 'fixed',
              left: 0,
              right: 0,
              // maxWidth: '75rem',
            }}
          ></div>

          <div
            style={{
              position: 'relative',
              zIndex: 0,
              overflow: 'hidden',
              width: '100%',
              height: '175px',
            }}
          >
            <img
              className='chuchuIcon'
              src='https://cdn.coffeebeats.cn/%E5%8D%A1%E9%80%9A%E5%BD%A2%E8%B1%A1_%E6%A5%9A%E6%A5%9A%E6%83%A0%E6%83%A0.png'
            />
            {/*{title && <span style={{ position: 'absolute', top: '30px', left: 0, width: '750px', textAlign: 'center', color: 'white', fontSize: '70px', zIndex: 1 }}>{title}</span>}*/}
            {/* {title && <div>
      <span style={{ position: 'absolute', top: '18px', left: '254px', width: '600px', textAlign: 'center', color: '#353535', fontSize: '33px', zIndex: 1, textShadow: '5px 5px 5prx #ddd' }}>{title[0]}</span>
      <span style={{ position: 'absolute', top: '66px', left: '254px', width: '600px', textAlign: 'center', color: '#353535', fontSize: '28px', zIndex: 1, textShadow: '5px 5px 5prx #ddd' }}>{title[1]}</span>
      <span style={{ position: 'absolute', top: '111px', left: '254px', width: '600px', textAlign: 'center', color: '#353535', fontSize: '30px', zIndex: 1, textShadow: '5px 5px 5prx #ddd' }}>{title[2]}</span>
    </div>} */}
            <img
              src='https://cdn.coffeebeats.cn/%E5%9B%BE%E7%89%871.png'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '175px',
              }}
            />
          </div>

          <div
            className='indexButton'
            style={{ position: 'relative', zIndex: 1, background: 'unset' }}
          >
            <img
              className='buttonImage'
              src='https://cdn.coffeebeats.cn/ll.png'
            />
            <span style={{ color: '#333' }}>办事网点</span>
          </div>

          <div className='indexBottombuttonsWrapper'>
            {/* <Image className="imgScene" mode="widthFill" src="http://oss-open.aichan.info/imgs/hhhhhhh.png"/> */}
            <Card>
              {spots.slice(0, 6).map((q, i) => (
                <div
                  key={i}
                  className='contact-info'
                  style={{
                    display: 'flex',
                    padding: '24px 12px',
                    borderBottom: 'solid thin rgba(221,221,221,0.5)',
                  }}
                >
                  {/* <span style={{ color: i <= 2 ? 'red' : (i <= 3 ? 'orange' : 'gray') }}>{i+1}</span>.  */}
                  <div style={{ flex: 2 }}>
                    <div style={{ fontSize: '14px', marginBottom: '6px' }}>
                      {q.name}
                    </div>
                    <div style={{ fontSize: '11px', color: '#888' }}>
                      {q.address}
                    </div>
                  </div>
                  <div style={{ flex: 3 }}>
                    <div
                      style={{ fontSize: '14px', marginBottom: '6px' }}
                      onClick={() => {}}
                    >
                      {q.contact}
                    </div>
                    <div style={{ fontSize: '14px', color: '#888' }}>
                      {q.workTime}
                    </div>
                  </div>
                </div>
              ))}
            </Card>
          </div>
        </Frame>
      )}
    </>
  );
};
