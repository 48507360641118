import { Image } from 'antd';
import React, { useMemo, useEffect, useState } from 'react';
import './index.css';
import PageHeader from '../../utils/header/header';
const Hongshan = () => {
  const spots = {
    huangshi: [
      {
        name: '洪山政务服务中心',
        address: '洪山区文秀街9号',
        contact: '027-65397104',
        workTime: (
          <div>
            周一至周五9:00-17:00（8:30可取号）
            <br />
            周六：9：00-16：00
            <br />
            所有假日与国家法定节假日同步。
            （若需要办理继承询问笔录业务，律师工作时间为周一至周五，早上9点至12点，下午1点30至5点）
          </div>
        ),
      },
    ],
    default: [
      {
        name: '武汉政务服务中心',
        address: '洪山区文秀街9号',
        contact: '027-65397104',
        workTime: (
          <div>
            周一至周五9:00-17:00（8:30可取号）
            <br />
            周六：9：00-16：00
            <br />
            所有假日与国家法定节假日同步。
            （若需要办理继承询问笔录业务，律师工作时间为周一至周五，早上9点至12点，下午1点30至5点）
          </div>
        ),
      },
    ],
  } as any;
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const handleTabletChange = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => setIsMobile(e.matches);

    mediaQuery.addListener(handleTabletChange);
    return () => mediaQuery.removeListener(handleTabletChange);
  }, []);

  const currentSpot = useMemo(() => {
    const hostPrefix = window.location.host.split('.')?.[0] || '';
    return (spots[hostPrefix] as any) || spots['default'];
  }, [window.location.host]);

  return (
    <>
      {isMobile ? (
        <div className='bg'>
          <PageHeader />
          <div className='thingsNetPanal'>
            <div className='netContent'>办事网点</div>

            {currentSpot.map((spot: any, index: number) => (
              <div key={index}>
                <div className='nameCenterPanal'>
                  <div className='nameContent'>{spot.name}</div>
                  <Image
                    src='https://oss-open.aichan.info/imgs/H5/%E6%94%BF%E5%8A%A1%E4%B8%AD%E5%BF%83%401x%201%402x.png'
                    className='zhengwuCenterImg'
                    alt={spot.name}
                    style={{
                      width: '14px',
                      height: '12px',
                    }}
                  />
                </div>
                <div className='addressContent'>地址：{spot.address}</div>
                <div className='phoneContent'>电话：{spot.contact}</div>
                <div className='workTimePanal'>
                  <div className='workContent'>办公时间：</div>
                  <div className='addressContent'>{spot.workTime}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='bgPC'>
          <PageHeader />
          <div className='thingsNetPanal'>
            <div className='netContent'>办事网点</div>

            {currentSpot.map((spot: any, index: number) => (
              <div key={index}>
                <div className='nameCenterPanal'>
                  <div className='nameContent'>{spot.name}</div>
                  <Image
                    src='https://oss-open.aichan.info/imgs/H5/%E6%94%BF%E5%8A%A1%E4%B8%AD%E5%BF%83%401x%201%402x.png'
                    className='zhengwuCenterImg'
                    alt={spot.name}
                    style={{
                      width: '14px',
                      height: '12px',
                    }}
                  />
                </div>
                <div className='addressContent'>地址：{spot.address}</div>
                <div className='phoneContent'>电话：{spot.contact}</div>
                <div className='workTimePanal'>
                  <div className='workContent'>办公时间：</div>
                  <div className='addressContent'>{spot.workTime}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Hongshan;
