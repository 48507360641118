import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Image,
  Button,
  Card,
  Col,
  Input,
  Row,
  Skeleton,
  Space,
  message as Message,
  Modal,
  message,
  Affix,
} from 'antd';
import './index.css';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../utils/header/header';
import { commonFeedback } from '../../apis/qa';
import { useSearchParams } from 'react-router-dom';

const { TextArea } = Input;

const Ssss = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const error = () => {
    messageApi.open({
      type: 'error',
      content: '敬请期待',
    });
  };

  // 请求热门tags、及参数中tag或者问题的答案
  const [searchParams] = useSearchParams();
  const query = searchParams.get('mobile');

  console.log(99, query);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const handleTabletChange = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => setIsMobile(e.matches);

    mediaQuery.addListener(handleTabletChange);
    return () => mediaQuery.removeListener(handleTabletChange);
  }, []);

  return (
    <>
      {contextHolder}
      <PageHeader title={document.title}/>
      {isMobile ? (
        <div
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            top: 40,
            width: '100%',
            zIndex: 1,
            backgroundColor: '#F8F8F8',
          }}
        >
          <div className='inforCard'>
            <div className='infoTitle'>常用功能</div>

            <div className='onlineDealPanal'>
              {/* <div
                className='avangeWidth'
                onClick={async () => {
                  await navigate(`/offlineOffice`);
                }}
              >
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E5%8A%9E%E4%BA%8B%E7%BD%91%E7%82%B9%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>办事网点</div>
              </div> */}
              <div onClick={error} className='avangeWidth'>
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E4%B8%8B%E8%BD%BD%E6%96%87%E4%BB%B6%201%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>下载文件</div>
              </div>

              <div
                onClick={() => {
                  Modal.confirm({
                    title: '改进建议',
                    content: (
                      <TextArea
                        onChange={(e) => {
                          console.log(e.currentTarget.value, '==e===');
                          (window as any).feedbackText = e.currentTarget.value;
                        }}
                        rows={4}
                        style={{
                          fontSize: '12px',
                          margin: '10px 0',
                          padding: '12px',
                          background: '#eee',
                          borderRadius: '8px',
                          width: 'calc(100% - 24px)',
                        }}
                        placeholder='请输入改进建议'
                        name='textarea'
                      />
                    ),
                    okText: '确认',
                    onOk: async () => {
                      if (!(window as any).feedbackText) {
                        return;
                      }
                      const res = await commonFeedback(
                        (window as any).feedbackText
                      );
                      messageApi.open({
                        type: 'success',
                        content: '感谢您的反馈',
                      });
                      // setShowTextArea(false);
                    },
                    cancelText: '取消',
                  });
                }}
                className='avangeWidth'
              >
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E6%94%B9%E8%BF%9B%E5%BB%BA%E8%AE%AE%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>改进意见</div>
              </div>
              <div onClick={error} className='avangeWidth'>
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E6%9B%B4%E5%A4%9A%E6%9C%8D%E5%8A%A1%201%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>更多服务</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            top: 40,
            width: '100%',
            zIndex: 1,
            backgroundColor: '#F8F8F8',
            margin: '0 auto',
            maxWidth: '750px',
          }}
        >
          <div className='inforCard'>
            <div className='infoTitle'>常用功能</div>

            <div className='onlineDealPanal'>
              {/* <div
                className='avangeWidth'
                onClick={async () => {
                  await navigate(`/offlineOffice`);
                }}
              >
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E5%8A%9E%E4%BA%8B%E7%BD%91%E7%82%B9%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>办事网点</div>
              </div> */}
              <div onClick={error} className='avangeWidth'>
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E4%B8%8B%E8%BD%BD%E6%96%87%E4%BB%B6%201%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>下载文件</div>
              </div>

              <div
                onClick={() => {
                  Modal.confirm({
                    title: '改进建议',
                    content: (
                      <TextArea
                        onChange={(e) => {
                          console.log(e.currentTarget.value, '==e===');
                          (window as any).feedbackText = e.currentTarget.value;
                        }}
                        rows={4}
                        style={{
                          fontSize: '12px',
                          margin: '10px 0',
                          padding: '12px',
                          background: '#eee',
                          borderRadius: '8px',
                          width: 'calc(100% - 24px)',
                        }}
                        placeholder='请输入改进建议'
                        name='textarea'
                      />
                    ),
                    okText: '确认',
                    onOk: async () => {
                      if (!(window as any).feedbackText) {
                        return;
                      }
                      const res = await commonFeedback(
                        (window as any).feedbackText
                      );
                      messageApi.open({
                        type: 'success',
                        content: '感谢您的反馈',
                      });
                      // setShowTextArea(false);
                    },
                    cancelText: '取消',
                  });
                }}
                className='avangeWidth'
              >
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E6%94%B9%E8%BF%9B%E5%BB%BA%E8%AE%AE%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>改进意见</div>
              </div>
              <div onClick={error} className='avangeWidth'>
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E6%9B%B4%E5%A4%9A%E6%9C%8D%E5%8A%A1%201%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>更多服务</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {!isMobile ? (
        <div
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            top: 40,
            width: '750px',
            zIndex: 1,
            backgroundColor: '#F8F8F8',
            margin: '0 auto',
            maxWidth: '750px',
          }}
        >
          <div className='inforCard'>
            <div className='infoTitle'>常用功能1</div>

            <div className='onlineDealPanal'>
              <div
                className='avangeWidth'
                onClick={async () => {
                  await navigate(`/offlineOffice`);
                }}
              >
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E5%8A%9E%E4%BA%8B%E7%BD%91%E7%82%B9%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>办事网点</div>
              </div>
              <div onClick={error} className='avangeWidth'>
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E4%B8%8B%E8%BD%BD%E6%96%87%E4%BB%B6%201%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>下载文件</div>
              </div>

              <div
                onClick={() => {
                  Modal.confirm({
                    title: '改进建议',
                    content: (
                      <TextArea
                        onChange={(e) => {
                          console.log(e.currentTarget.value, '==e===');
                          (window as any).feedbackText = e.currentTarget.value;
                        }}
                        rows={4}
                        style={{
                          fontSize: '12px',
                          margin: '10px 0',
                          padding: '12px',
                          background: '#eee',
                          borderRadius: '8px',
                          width: 'calc(100% - 24px)',
                        }}
                        placeholder='请输入改进建议'
                        name='textarea'
                      />
                    ),
                    okText: '确认',
                    onOk: async () => {
                      if (!(window as any).feedbackText) {
                        return;
                      }
                      const res = await commonFeedback(
                        (window as any).feedbackText
                      );
                      messageApi.open({
                        type: 'success',
                        content: '感谢您的反馈',
                      });
                      // setShowTextArea(false);
                    },
                    cancelText: '取消',
                  });
                }}
                className='avangeWidth'
              >
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E6%94%B9%E8%BF%9B%E5%BB%BA%E8%AE%AE%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>改进意见</div>
              </div>
              <div onClick={error} className='avangeWidth'>
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E6%9B%B4%E5%A4%9A%E6%9C%8D%E5%8A%A1%201%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>更多服务</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            top: 40,
            width: '100%',
            zIndex: 1,
            backgroundColor: '#F8F8F8',
          }}
        >
          <div className='inforCard'>
            <div className='infoTitle'>常用功能</div>

            <div className='onlineDealPanal'>
              <div
                className='avangeWidth'
                onClick={async () => {
                  await navigate(`/offlineOffice`);
                }}
              >
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E5%8A%9E%E4%BA%8B%E7%BD%91%E7%82%B9%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>办事网点</div>
              </div>
              <div onClick={error} className='avangeWidth'>
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E4%B8%8B%E8%BD%BD%E6%96%87%E4%BB%B6%201%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>下载文件</div>
              </div>

              <div
                onClick={() => {
                  Modal.confirm({
                    title: '改进建议',
                    content: (
                      <TextArea
                        onChange={(e) => {
                          console.log(e.currentTarget.value, '==e===');
                          (window as any).feedbackText = e.currentTarget.value;
                        }}
                        rows={4}
                        style={{
                          fontSize: '12px',
                          margin: '10px 0',
                          padding: '12px',
                          background: '#eee',
                          borderRadius: '8px',
                          width: 'calc(100% - 24px)',
                        }}
                        placeholder='请输入改进建议'
                        name='textarea'
                      />
                    ),
                    okText: '确认',
                    onOk: async () => {
                      if (!(window as any).feedbackText) {
                        return;
                      }
                      const res = await commonFeedback(
                        (window as any).feedbackText
                      );
                      messageApi.open({
                        type: 'success',
                        content: '感谢您的反馈',
                      });
                      // setShowTextArea(false);
                    },
                    cancelText: '取消',
                  });
                }}
                className='avangeWidth'
              >
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E6%94%B9%E8%BF%9B%E5%BB%BA%E8%AE%AE%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>改进意见</div>
              </div>
              <div onClick={error} className='avangeWidth'>
                <Image
                  src='https://oss-open.aichan.info/imgs/H5/%E6%9B%B4%E5%A4%9A%E6%9C%8D%E5%8A%A1%201%402x.png'
                  className='banshiImage'
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                  preview={false}
                ></Image>
                <div className='onlineContent'>更多服务</div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Ssss;
