import { apiRequest, ApiResponse, requestApiWithToken } from '../utils/request';

// 获取热门问题
export async function getHotQuestions(tag: string = '', category_2: string = '',): Promise<{ questions: string[], tags: string[], category_2s: string[] } & ApiResponse> {
  return await apiRequest({ path: 'api/qa/hot_questions', method: 'GET', data: { tag, category_2, mini_id: window.location.host  } });
}

// 回答问题
export async function qa(question: string, userType: string = ""): Promise<{ answer: string, record_id: string, record_create_at: string, call_human: boolean, related_questions: string[], answer_type?: string } & ApiResponse> {
// export async function qa(question: string): Promise<{ answer: string, recordId: string, relatedQuestions: string[] } & ApiResponse> {
  return await requestApiWithToken({ path: 'api/qa/a', method: 'POST', data: {question, mini_id: window.location.host  } });
}


// 获取热门文件
export async function getHotFiles(): Promise<{ files: { name: string, url: string }[] } & ApiResponse> {
  return await requestApiWithToken({ path: 'api/qa/hot_files', method: 'GET', data: { mini_id: window.location.host } });
}

// 反馈信息
export async function feedback(record_id: string, attitude: string, message: string): Promise<ApiResponse> {
  return await requestApiWithToken({ path: 'api/qa/feedback', method: 'POST', data: {record_id, attitude, message, mini_id: window.location.host } });
}

// 获取历史问答信息
export async function getHistoryQas(before_which_id: string, page_size: number = 5): Promise<{ list: { question: string, answer: string, id: string, create_at: string, answer_type?: string }[] } & ApiResponse> {
  return await requestApiWithToken({ path: 'api/qa/h-list', method: 'GET', data: {before_which_id, page_size} });
}


// 反馈信息
export async function commonFeedback(message: string): Promise<{data:[]}&ApiResponse> {
  return await requestApiWithToken({ path: 'api/qa/common-feedback', method: 'POST', data: { message} });
}


// 在线留言
export async function onlineMessage(message: string): Promise<ApiResponse> {
  return await requestApiWithToken({ path: 'api/qa/common-feedback', method: 'POST', data: { message, attitude: 'comment' } });
}

// 获取区域地名
export async function queryAreaList(): Promise<{ data: { CHILD:any}[] } & ApiResponse> {
  return await apiRequest({ path: 'api/account/wh/area/queryAreaList', method: 'GET', data: {  } });
}


// 回答问题
export async function recordUser(id: string, userName: string,  idCard: string,phone:string):  Promise<{ data: {} } & ApiResponse> {
  // export async function qa(question: string): Promise<{ answer: string, recordId: string, relatedQuestions: string[] } & ApiResponse> {
    return await requestApiWithToken({ path: 'backend-api/record-user/', method: 'POST', data: {id, userName,  idCard,phone } });
}


// 回答问题
export async function checkReception(data:string):  Promise<{ data: {} } & ApiResponse> {
  // export async function qa(question: string): Promise<{ answer: string, recordId: string, relatedQuestions: string[] } & ApiResponse> {
    return await requestApiWithToken({ path: 'api/account/applet/operate/checkReception', method: 'POST', data:{data} });
}

