import React, { createContext, useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { IndexPage } from './pages/index';
import { QaPage } from './pages/qa/qa';
import SpotPage from './pages/spot/index';
import { getBase } from './apis/account';
import OnlineOffice from './pages/transact';
import OfflineOffice from './pages/hongshan';

export const AppContext = createContext<{
  title: string;
  showLogo: boolean;
  apiHost: string;
}>({
  title: '',
  showLogo: false,
  apiHost: '',
});

function App() {
  const [showLogo, showLogoSetter] = useState(false);
  const [title, titleSetter] = useState('');
  const [apiHost, apiHostSetter] = useState('');

  useEffect(() => {
    (async () => {
      console.log(999988);
      const miniId = window.location.host;
      const { title, showLogo, apiHost } = await getBase(miniId);
      showLogoSetter(showLogo);
      titleSetter(title);
      apiHostSetter(apiHost);
      document.title = title;
    })();
  });

  return (
    <div className='App'>
      <AppContext.Provider value={{ showLogo, title, apiHost }}>
        <Routes>
          <Route path='/' element={<IndexPage />} />
          <Route path='/qa/qa' element={<QaPage />} />
          <Route path='/spot' element={<SpotPage />} />
          <Route path='/onlineOffice' element={<OnlineOffice />} />
          <Route path='/offlineOffice' element={<OfflineOffice />} />
        </Routes>
      </AppContext.Provider>
    </div>
  );
}

export default App;
