export function lockPage () {
  document.body.style.overflow = 'hidden';
  document.addEventListener('touchmove', preventScroll, { passive: false });
}

export function unlockPage () {
  document.body.style.overflow = '';
  document.removeEventListener('touchmove', preventScroll);
}

const preventScroll = (event: { preventDefault: () => void }) => {
  event.preventDefault(); // 阻止滚动
};

