/* eslint-disable */
import { useLocation } from 'react-router-dom'
import React from 'react'
import CryptoJS from 'crypto-js';

const encrypt =  (word:any, keyStr = 'whbdcznkf_2024_1') =>{

  // let iv = CryptoJS.enc.Utf8.parse()
  let key = CryptoJS.enc.Utf8.parse(keyStr)
  let src = CryptoJS.enc.Utf8.parse(word)
  let encrypted = (CryptoJS as any).AES.encrypt(src,key, {
    mode:(CryptoJS as any).mode.ECB,
    padding:(CryptoJS as any).pad.Pkcs7
  })
  return encrypted.toString()
}



const  decrypt =   (word:any, keyStr = 'whbdcznkf_2024_1') =>{

  // let iv = CryptoJS.enc.Utf8.parse()
  let key = CryptoJS.enc.Utf8.parse(keyStr)

  let decrypt = (CryptoJS as any).AES.decrypt(word,key, {
    mode:(CryptoJS as any).mode.ECB,
    padding:(CryptoJS as any).pad.Pkcs7
  })
   return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  //  return decrypt.toString()
}


export default{
  encrypt,
  decrypt
}