import React, { useState } from 'react';
import { Button, Card, Skeleton, Tabs, message, Modal } from 'antd';
import PDFViewer from './pdfViewer';
import './index.css';

interface FileProps {
  needSaveFile?: boolean;
  needOpenFile?: boolean;
  needCheckDetail?: boolean;
  needImage?: boolean;
  files?: any[];
  maxLength?: number;
  isOpenCareMode?: boolean;
}

export const openFile = async (q: any) => {
  window.open(q.url)
};

export default (props: FileProps) => {
  const {
    files = [],
    needSaveFile = false,
    needOpenFile = true,
    needCheckDetail = false,
    needImage = true,
    maxLength = 10,
    isOpenCareMode = false,
  } = props;

  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");


  const openFile = async (q: any) => {
    if (needCheckDetail) {
      window.open(q.url)
      // await redirectTo({
      //   url: `/pages/image/index?src=${q.url}`
      // })
      return;
    }
    window.open(q.url)
  };

  console.log((window as any).currentUrl , '===currentUrl===')

  return (
    <>
      <Card>
        {files.slice(0, maxLength).map((q, i) => (
          <div key={i} className='file-item' onClick={async () => {
            setCurrentUrl(q.url);
            setShowPdfViewer(true);
            (window as any).showPdfViewer = true;
            (window as any).currentUrl = q.url;
          }}>
            {/* <Text style={{ color: i <= 2 ? 'red' : (i <= 3 ? 'orange' : 'gray') }}>{i+1}</Text>.  */}
            {/* {needImage && <Image src="https://cdn.coffeebeats.cn/nn.png" style={{ width: '36px', height: '30px', marginRight: 10, position: "relative", top: "5px" }} /> } */}
            <div className={isOpenCareMode ? 'cardContetBig' : 'name'}>
              {q?.name}
            </div>

            <Button className='btn'>查看</Button>
          </div>
        ))}
      </Card>
      <PDFViewer show={(window as any).showPdfViewer} onClose={() => {
        (window as any).showPdfViewer = false;
        setShowPdfViewer(false)
      }} pdf_url={(window as any).currentUrl } />
    </>
  );
};
