import React, { useEffect } from 'react';
// pdfjs-dist/webpack which is the zero-configuration method for Webpack users:
// import PDFJS from 'pdfjs-dist';

// import workerSrc from './pdf.worker.mjs';


// PDFJS.workerSrc = workerSrc;

// 创建canvas
function createPage() {
  const div = document.createElement("canvas");
  const container = document.getElementById('pdf-container')
  container && container.appendChild(div);
  return div;
}

// canvas渲染
function renderPage(pdfDoc: any, num: any) {
  pdfDoc.getPage(num).then(function (page: any) {
    var viewport = page.getViewport({scale:2.0});
    var canvas = createPage();
    var ctx = canvas.getContext('2d');

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    page.render({
      canvasContext: ctx,
      viewport: viewport
    });
  });
}


const PDFViewer = (props: any) => {
  // 可以通过props传入url
  const { pdf_url, onClose, show } = props

  useEffect(() => {
    if (pdf_url) {
      // (window as any).bridge?.showFullLoading()
      // PDFJS.getDocument(pdf_url).promise
      //   .then(function (pdf) {
      //     for (let i = 1; i <= pdf.numPages; i++) {
      //       renderPage(pdf,i)
      //     }
      //   })
      //   .finally(() => {
      //     (window as any).bridge?.hideFullLoading()
      //   })
    }
  }, [pdf_url])

  console.log(show, 9988)

  if (!show) {
    return null
  }

  return (
    <div onClick={() => {
      onClose();
    }} style={{ zIndex: 100, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,0.35)", display: "flex",justifyContent: 'center', alignItems: 'center'}}>
      <iframe onClick={(e) => {
        e.stopPropagation();
      }} width="90%" height="60%"  src={pdf_url}></iframe>
    </div>

  )
}

export default PDFViewer